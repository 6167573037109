<template>
  <div>
    <!-- pic351155E4-4363-46CC-AD7D-9E1B15DC6AF7.jpg -->
    <img class="leftimage rounded w-200 h-267" src="">
    <p>
      Professional developers aren't the only ones making major decisions about LEGO Universe's production; there's also a LU Kids' development panel affecting every aspect of the game's creation.
    </p>
    <p>
      The LEGO Universe News Network caught up with Reed, one of the panel's lucky members, and his dad to uncover some shrouded information exclusively for our newsletter subscribers. This is the first time a member of the Kids' development panel has spoken publicly, so read on for the juiciest tidbits!
    </p>
    <div class="clear"></div>
    <p>
      <b>Hi Reed. How old are you?</b>
    </p>
    <p>
      I'm ten.
    </p>
    <p>
      <b>What are your hobbies?</b>
    </p>
    <p>
      I'm in a Pokemon league and I also like playing baseball and soccer… and of course LEGO and computer gaming!
    </p>
    <p>
      <b>What are your favorite LEGO sets?</b>
    </p>
    <p>
      I like Green Dragon and all of the Bionicle sets the best.
    </p>
    <p>
      <b>What do you usually build with LEGO? </b>
    </p>
    <p>
      My favorite things to build are Bionicle kits. I also like mixing and matching parts to invent new Bionicles. With the regular LEGO blocks, I like using my imagination to create made-up things.<br> <br><b>How do you like working with the professional game developers of the LEGO Universe team?</b><br> <br>It's really cool. I get to see the LEGO Universe being made, and there are a lot of LEGO models on display there that look amazing!
    </p>
    <p>
      <b>Does working on LU make you want to be a professional developer too?</b>
    </p>
    <p>
      YES! I’m already beginning to create a new game with my friends at school.
    </p>
    <p>
      <b>Which parts of LU's development have you been involved in?</b>
    </p>
    <p>
      I've given input on game ideas, pictures, artwork, and the name of the game. Most recently, I got to play the parts of the game that have been developed so far!
    </p>
    <p>
      <b>Do you think "LEGO Universe" was the best name choice?</b>
    </p>
    <p>
      Definitely. It tells you what the game is, and that it’s a BIG area.
    </p>
    <p>
      <b>What can you tell LU fans to get them excited?</b>
    </p>
    <p>
      The graphics are amazing, the game is already fun to play, and the individual LEGO's are really detailed. It looks like it’s gonna be a great game!
    </p>
    <p>
      <b>What will your minifig look like in the game?</b>
    </p>
    <p>
      I want my minifig to look like a magi or a knight.
    </p>
    <p>
      <b>Anything else you want to tell everyone?</b><br> <br>It’s really cool that I got chosen to do this, and it’s been a lot of fun. Thanks LEGO!
    </p>
    <p>
      <br>Here’s what Reed’s dad, Ken, had to say about LU and his son’ involvement on the Kids' development panel.
    </p>
    <p>
      <b>What's your impression of Reed's involvement with LU so far?</b>
    </p>
    <p>
      It's been a phenomenal experience. LEGO Universe team has been very supportive of Reed; they always make him feel welcome and he’s truly having a blast. <br> <br><b>What do you think Reed will gain from the experience?</b>
    </p>
    <p>
      Well, he’s learning how to provide objective feedback to a team of professional programmers; That means he's learning how to speak clearly and precisely about things he likes and doesn’t like regarding the developing game. Best of all, Reed’s gaining insight into the level of involvement required to publish a professional computer game.
    </p>
    <p>
      <b>How do you think LEGO Universe will compare with other games your child plays?</b>
    </p>
    <p>
      As a parent, I see two improvements over existing games that have me excited. First, LU requires both imagination and thinking; I can’t wait to see what Reed builds in this new virtual universe. The second improvement is the ‘family-safe’ environment; imagine a game that’s, filled with action, loads of fun and great 3D graphics, but without gruesome blood!
    </p>
    <p>
      <b>Anything else you want to say about your son being part of LU’s development?</b>
    </p>
    <p>
      I know there are thousands of children who would love to have the opportunity Reed's getting. He appreciates what a special time this is. The chance to be a part of a brand new game is not lost on him and he's always eager to head back over to the LEGO Universe offices to see the latest progress on the LEGO Universe. Thanks for keeping him on the team!
    </p>
  </div>
</template>
